<template>
  <div id="pop-preview" class="dialog" :class="{ on, ing }">
    <div class="pop-wrap preview-type" :style="`max-width: ${maxWidth};`">
      <h1 v-if="title">
        {{ title }}
        <!-- 미리보기 – 6시간 간격으로 최상위 순위로 올라가는 옵션 -->
      </h1>
      <div class="cont">
        <div class="preview-item-wrap">
          <!-- <img src="~assets/image/etc/etc-hotel-noimg.svg" /> -->
          <img :src="imgPath" />
        </div>
      </div>
      <div class="pop-btn-wrap">
        <button type="button" class="btn-close" @click="close">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'
export default {
  mixins: [popupMixin],
  props: {
    imgPath: {
      type: String,
    },
    title: {
      type: String,
    },
    maxWidth: {
      type: String,
    },
  },
}
</script>

<style scoped>
.dialog {
  position: fixed;
}
</style>
